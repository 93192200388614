.App {
  text-align: center;
}
h4, h3{
  color: #66b82c;
}
.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}
h3{
  border-bottom: 1px solid;
  text-align: left;
  padding: 5px 10px;
}
h3 svg{
  margin-right: 15px;
}
h3 svg:nth-child(2){
  float: right;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.rightPading{
  padding-right: 15px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  background: url("../public/sky.png")  no-repeat; 
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: -3px 3px 14px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: -3px 3px 14px -2px rgba(0,0,0,0.75);
-moz-box-shadow: -3px 3px 14px -2px rgba(0,0,0,0.75);
}
.flowerImg{
  left: 0;
  z-index: 3;
  max-width: 25%;
}
.logo{
  z-index: 3;
  width: 165px;
  right: 0;
  left: auto;
}
.App-header img{
  left: 0;
  max-width: 100%;
  padding-top: 20px;
}
.App-header .handImg{
  left: auto;
  right: 0;
  bottom: 39%;
  max-width: 25%;
  z-index: 2;
}
.App-header .carImg{
  bottom: 0px;
  right: 0;
  left: auto;
  max-width: 50%;
  z-index: 2;
}
.App-header .mainBuilding{
  z-index: 1;
}
.App-header .logo{
  z-index: 3;
  right: 0;
  left: auto;
}
.App-link {
  color: #61dafb;
}

.navbar-collapse{
  flex-direction: row-reverse;
}
.fssaiWrapper{
  text-align: left;
}
.fssaiWrapper img{
  transition: 1s ease;
}
.fssaiWrapper img:hover{
  -webkit-transform: rotateZ(-10deg);
-ms-transform: rotateZ(-10deg);
transform: rotateZ(-10deg);
transition: 1s ease;
}
.menuLogoWrapper{
  text-align: left;
}
.menuLogoWrapper img{
  width: 115px;
  background: #fff;
  border: 2px dashed #6fb43d;
  box-shadow: 0px 15px 10px -15px #111; 
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.navbar-nav .nav-link.active{
  background: #6fb43d;
  color: #fff!important;
  -webkit-box-shadow:0px 1px 5px 0px rgba(0,0,0,0.5);
-moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.5);
box-shadow:0px 1px 5px 0px rgba(0,0,0,0.5);
}
.navbar-nav .nav-link{
  position: relative;
  min-width: 95px;
  padding: 18px 15px;
  background:
  conic-gradient(            #fff 0 90deg, #fff0 0) bottom left,
  conic-gradient(            #6fb43d 0 90deg, #fff0 0) bottom left,
  conic-gradient(from  90deg,#fff 0 90deg, #fff0 0) top left,
  conic-gradient(from  90deg,#6fb43d 0 90deg, #fff0 0) top left,
  conic-gradient(from 180deg,#fff 0 90deg, #fff0 0) top right,
  conic-gradient(from 180deg,#6fb43d 0 90deg, #fff0 0) top right,
  conic-gradient(from -90deg,#fff 0 90deg, #fff0 0) bottom right,
  conic-gradient(from -90deg,#6fb43d 0 90deg, #fff0 0) bottom right,
  #000;
-webkit-background-clip:text,padding-box;
        background-clip:text,padding-box;
background-size: 202% 202%;
transition: 0.5s;
}
.navbar-nav .nav-link:hover{
  background-position: center;
  color: #fff;
}
.navbar-nav .hoverEffetWrapper{
  margin-right: 10px;
}
.navbar-nav .hoverEffetWrapper:after{
  position: absolute;
  right: -5px;
  content: "";
  top: 0;
  bottom: 0;
  background: #4a7829;
  width: 1px;
}
.navbar-nav .hoverEffetWrapper:before{
  position: absolute;
  right: -6px;
  content: "";
  top: 0;
  bottom: 0;
  background: #8ee151;
  width: 2px;
}
.fixedHeader{
  position: fixed;
    top: 0;
    z-index: 5; 
    box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.5);
}
.pageWrapper{
  min-height: 100vh;
  border: 1px solid #999;
}
.gasPump{
  font-size: 65px;
  color: #6fb43d;
  text-align: right;
}
.mediaWrapper ul{
  list-style: none;
  border: 1px solid #ccc;
  background: #fff;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0px 20px;
}
.mediaWrapper ul li{
  color:  #66b82c;
  padding: 5px 20px;
  font-size: 1.5em;
  cursor: pointer; 
  transition: all 0.5s;
}
.mediaWrapper ul li:hover{
  color: #000;
} 
p.quates{
  margin-top: 25px;
}
p.quates span, .videoWrapper p >span, p.quates2 span{
  color: #f7b60a;
  font-weight: bold;
}
.outer{
  min-height: 60px;
}
.menuOuterWrapper{
  border-bottom: 1px solid #6fb43d;
  background: #f2f9ec;
  border: 1px solid #6fb43d;
  height: 60px; 
}
.videoWrapper{
  max-height: 500px; 
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
}
.videoWrapper p{
  position: absolute;
  z-index: 4;
  color: #fff;
  font-size: 2em;
  text-align: center;
}
video{
  width: 100%;
  filter: blur(4px);
}
.missionWrapper img, .vissionWrapper img{
  width: 200px;
  border: 1px solid #ccc;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
}
.missionOuter{
  border: 1px solid #999;
  margin-top: 15px;
  padding: 0px 15px;
}
.missionWrapper > div{
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.missionWrapper > div div{
  float: right;
    width: calc( 100% - 200px );
    padding-left: 15px;
}
.vissionWrapper > div div{
  float: left;
  text-align: left;
  width: calc( 100% - 200px );
}

.button {
  position: relative;
  background: #000;
  padding: 16px 30px;
  font-size: 1.5rem;
  color: #f7b60a;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  text-shadow: 0 0 15px #6fb43d;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: 0.5s;
  z-index: 1;
}

.button:hover {
  color: #fff;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0px #6fb43d;
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #6fb43d;
  z-index: -1;
  transform: scale(0);
  transition: 0.5s;
}

.button:hover::before {
  transform: scale(1);
  transition-delay: 0.5s;
  box-shadow: 0 0 10px #6fb43d,
    0 0 30px #6fb43d,
    0 0 60px #6fb43d;
}

.button span {
  position: absolute;
  background: #6fb43d;
  pointer-events: none;
  border-radius: 2px;
  box-shadow: 0 0 10px #6fb43d,
    0 0 20px #6fb43d,
    0 0 30px #6fb43d,
    0 0 50px #6fb43d,
    0 0 100px #6fb43d;
  transition: 0.5s ease-in-out;
  transition-delay: 0.25s;
}

.button:hover span {
  opacity: 0;
  transition-delay: 0s;
}

.button span:nth-child(1),
.button span:nth-child(3) {
  width: 40px;
  height: 4px;
}

.button:hover span:nth-child(1),
.button:hover span:nth-child(3) {
  transform: translateX(0);
}

.button span:nth-child(2),
.button span:nth-child(4) {
  width: 4px;
  height: 40px;
}

.button:hover span:nth-child(1),
.button:hover span:nth-child(3) {
  transform: translateY(0);
}

.button span:nth-child(1) {
  top: calc(50% - 2px);
  left: -50px;
  transform-origin: left;
}

.button:hover span:nth-child(1) {
  left: 50%;
}

.button span:nth-child(3) {
  top: calc(50% - 2px);
  right: -50px;
  transform-origin: right;
}

.button:hover span:nth-child(3) {
  right: 50%;
}

.button span:nth-child(2) {
  left: calc(50% - 2px);
  top: -50px;
  transform-origin: top;
}

.button:hover span:nth-child(2) {
  top: 50%;
}

.button span:nth-child(4) {
  left: calc(50% - 2px);
  bottom: -50px;
  transform-origin: bottom;
}

.button:hover span:nth-child(4 ) {
  bottom: 50%;
}
.card{
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5)
}
.card img{
  width: 100px;
    margin: 15px auto;
}
.card p{
  background: #6fb43d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.serviceBanner img{
  width: 100%;
  margin-top: 15px;
  filter: brightness(0.5);
}
.serviceBanner p{
  position: absolute;
  top: 35%;
  color: #fff;
  font-size: 25px;
  padding: 0px 20px;
}
.careerWrapper{
  border: 1px solid #999;
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
}
.careerWrapper img{
  width: 100%;
}.careerWrapper div:nth-child(2)::before{
  box-shadow: 0px 25px 5px 0px rgba(0,0,0,0.5);
  content: '';
  border-radius: 1% 10% 0% 62% / 0% 1% 2% 100%;
  background: #000;
  padding: 50%;
  z-index: 2;
  position: absolute;
  left: 70px;
}
.careerWrapper p{
  font-size: 20px;
  color: #ccc;
  z-index: 3;
}
.careerWrapper > div + div{
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  background: #6fb43d; 
  border-left: 2px dashed #ccc;
}